import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./screens/Home";
import Add from "./screens/Add";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/add",
    element: <Add/>,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
