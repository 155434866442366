import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Button,
  Box,
  Typography,
  Container,
  Grid,
  Slider,
  CssBaseline,
  ThemeProvider,
  createTheme,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import { Autocomplete } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
  },
});

const Add = () => {
  const [formData, setFormData] = useState({
    foto: '',
    genero: '',
    materias: [],
    tipo: [],
    localizacion: [],
    dificultad: 5,
    nivel_estudio: '',
    ano_ingreso: ''
  });
  const [loading, setLoading] = useState(false); 
  const [allFilled, setAllFilled] = useState(false)
  const [data, setData] = useState([])
  const [nameOptions, setNameOptions] = useState([]); 
  const [materiasOptions, setMateriasOptions] = useState(['Matemática', 'Operaciones y Sistemas', 'Entrepreneurship', 'Marketing Digital']);
  const [materiasInput, setMateriasInput] = useState('');  
  const allFieldsFilled = (formData) => {
    return (
      formData.nombre !== '' &&
      formData.foto !== '' &&
      formData.genero !== '' &&
      formData.materias.length > 0 &&
      formData.tipo.length > 0 &&
      formData.localizacion.length > 0 &&
      formData.nivel_estudio !== '' &&
      formData.ano_ingreso !== ''
    );
  };

  useEffect(()=>{
    setAllFilled(allFieldsFilled(formData))
  }, [formData])

    const fetchData = async () => {
      try {
        const response = await fetch("http://144.22.250.172:3000/add/read", {
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });
        const json = await response.json();
        setData(json.reverse());
        setNameOptions(json.map(item => item.nombre));
        const allMaterias = json
        .flatMap(item => item.materias) 
        .filter((materia, index, self) => materia && self.indexOf(materia) === index); 
        setMateriasOptions(allMaterias);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    useEffect(() => {
      fetchData();
    }, []); 
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      console.log(`Field ${name}: ${value}`);  
      setFormData({ ...formData, [name]: value });
    };
    
    const handleNombreChange = (event, newValue) => {
      setFormData({
        ...formData,
        nombre: newValue
      });
    };

  const handleMateriasChange = (event, newValue) => {
    setFormData({
      ...formData,
      materias: newValue,
    });
  };
  const handleMateriasInputChange = (event) => {
    setMateriasInput(event.target.value); 
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 

   
    const formDataToSend = {
      ...formData,
      dificultad: parseInt(formData.dificultad),
      ano_ingreso: parseInt(formData.ano_ingreso),
      materias: formData.materias,
      tipo: formData.tipo,
      localizacion: formData.localizacion
    };
    try {
      if(!allFilled){
        alert("Tenés que rellenar todos los campos.")
      }
      const response = await fetch("http://144.22.250.172:3000/add/create", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataToSend)
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.errors ? errorData.errors.join(', ') : 'Unknown error occurred');
      }
      
      const result = await response.json();
      
      console.log('Form submitted successfully:', result);
  
      
     
      setTimeout(() => {
        setFormData({
          nombre:'',
          foto: '',
          genero: '',
          materias: [],
          tipo: [],
          localizacion: [],
          dificultad: 5,
          nivel_estudio: '',
          ano_ingreso: ''
        });
      fetchData();

      }, 2000); 

    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setTimeout(() => {
        setLoading(false); 
      }, 1500);
    }
  };
  const handleDificultadChange = (event, newValue) => {
    setFormData({ ...formData, dificultad: newValue });
  };

  const claseList = ['Tutorial', 'Teórica', 'Problemas'];
  const locaList = ['Digital House', 'Campus', 'CABA'];

  const labels = {
    nombre:"Nombre Completo (Taylor Alison Swift)",
    foto:"Foto URL (https://www.google.com/url?sa=i&url=https%3A%2F%2Floomian-legacy.fandom.com%2Ff%2Fp%2F4400000000000360197&psig=AOvVaw2OWFNm6sBHxawhkS8paEPi&ust=1729435720152000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCNC6laDYmokDFQAAAAAdAAAAABAJ)",
    genero: 'Género',
    materias: "Materias (Matemática 1, Matemática 2)",
    tipo: "Tipo de clase",
    localizacion: "Sede",
    nivel_estudio: "Nivel de estudio (Máximo título alcanzado)",
    ano_ingreso: "Año de ingreso"
  }

  return (
    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
           <Grid item xs={12}>
           <TextField
                  fullWidth
                  label={labels.foto}
                  name="foto"
                  type="text"
                  value={formData.foto}
                  onChange={handleInputChange}
                  variant="filled"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                options={formData.nombre && formData.nombre.length > 2 ? nameOptions : []}
                value={formData.nombre}
                onChange={handleNombreChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={labels.nombre}
                    name="nombre"
                    onChange={handleInputChange}
                  />
                )}
              />
            </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Género</InputLabel>
                  <Select
                    name="genero"
                    value={formData.genero}
                    onChange={handleInputChange}
                    label={labels.genero}
                  >
                    <MenuItem value="masculino">Masculino</MenuItem>
                    <MenuItem value="femenino">Femenino</MenuItem>
                    <MenuItem value="otro">Otro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="filled">
                <Autocomplete
                    multiple
                    id="materias"
                    options={materiasInput.length > 2 ? materiasOptions : []}
                    value={formData.materias}
                    onChange={handleMateriasChange}
                    onInputChange={handleMateriasInputChange} 
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label={labels.materias}
                        placeholder="Selecciona o escribe materias"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    multiple
                    name="tipo"
                    value={formData.tipo}
                    onChange={handleInputChange}
                    label={labels.tipo}
                    renderValue={(selected) => selected.join(', ')}

                  >
                     {claseList.map((tipo) => (
                      <MenuItem key={tipo} value={tipo}>
                        <Checkbox checked={formData.tipo.indexOf(tipo) > -1} />
                        <ListItemText primary={tipo} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Sede</InputLabel>
                  <Select
                    multiple
                    name="localizacion"
                    value={formData.localizacion}
                    onChange={handleInputChange}
                    label={labels.localizacion}
                    renderValue={(selected) => selected.join(', ')}

                  >
                      {locaList.map((sede) => (
                      <MenuItem key={sede} value={sede}>
                        <Checkbox checked={formData.localizacion.indexOf(sede) > -1} />
                        <ListItemText primary={sede} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Dificultad: {formData.dificultad}</Typography>
                <Slider
                  name="dificultad"
                  value={formData.dificultad}
                  onChange={handleDificultadChange}
                  valueLabelDisplay="auto"
                  step={0.1}
                  marks
                  min={0}
                  max={10}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>{labels.nivel_estudio}</InputLabel>
                  <Select
                    name="nivel_estudio"
                    value={formData.nivel_estudio}
                    onChange={handleInputChange}
                    label={labels.nivel_estudio}
                  >
                    <MenuItem value="licenciatura">Licenciatura</MenuItem>
                    <MenuItem value="ingenieria">Ingeniería</MenuItem>
                    <MenuItem value="maestria">Maestria</MenuItem>
                    <MenuItem value="doctorado">Doctorado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={labels.ano_ingreso}
                  name="ano_ingreso"
                  type="number"
                  value={formData.ano_ingreso}
                  onChange={handleInputChange}
                  inputProps={{ min: 1988, max: 2025 }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  fullWidth 
                  disabled={allFilled ? false : true}
                >
                  {loading ? <CircularProgress color='black' size={24} /> : 'Añadir'}
                </Button>
              </Grid>
            </Grid>
          </form>
        {/* </Box> */}
      {/* </Container> */}
      <Box sx={{ mt: 4 }}>
            <TableContainer 
              component={Paper} 
              sx={{ maxHeight: '400px' }}
            >
              <Table stickyHeader aria-label="data table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#2b2b2b' }}>Nombre</TableCell>
                    <TableCell sx={{ backgroundColor: '#2b2b2b' }}>Género</TableCell>
                    <TableCell sx={{ backgroundColor: '#2b2b2b' }}>Materias</TableCell>
                    <TableCell sx={{ backgroundColor: '#2b2b2b' }}>Tipo</TableCell>
                    <TableCell sx={{ backgroundColor: '#2b2b2b' }}>Sede</TableCell>
                    <TableCell sx={{ backgroundColor: '#2b2b2b' }}>Dificultad</TableCell>
                    <TableCell sx={{ backgroundColor: '#2b2b2b' }}>Nivel Estudio</TableCell>
                    <TableCell sx={{ backgroundColor: '#2b2b2b' }}>Año Ingreso</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.filter(item => item.genero).map((row, index) => (
                    <TableRow
                      key={row.nombre}
                      sx={{ 
                        backgroundColor: index % 2 === 0 ? '#171717' : '#2b2b2b',
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { backgroundColor: '#171717' }
                      }}
                    >
                      <TableCell>{row.nombre}</TableCell>
                      <TableCell>{row.genero}</TableCell>
                      <TableCell>{row.materias.join(', ')}</TableCell>
                      <TableCell>{row.tipo.join(', ')}</TableCell>
                      <TableCell>{row.localizacion.join(', ')}</TableCell>
                      <TableCell>{row.dificultad}</TableCell>
                      <TableCell>{row.nivel_estudio}</TableCell>
                      <TableCell>{row.ano_ingreso}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Add;